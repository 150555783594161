<template>
  <b-row>
    <!-- Domicilio de notificacion -->
    <b-col
      cols="12"
      class="mt-1"
    >
      <b>{{ $t('notification-address') }}</b>
      <hr>
    </b-col>

    <!-- Address -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="6"
      xl="6"
    >
      <b-form-group>
        <label>{{ $t('address') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required|max:255|addressRegex"
          name="transferAddress"
          :immediate="isInmediate"
        >
          <b-form-input
            v-model="member.contractual_condition.transfer.address.address"
            type="text"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('stree-and-number')"
            @change="() => saveDataTemporarily()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <!-- postal code -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label>{{ $t('postal-code') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required|max:255|zipRegex"
          name="transferPostalCode"
          :immediate="isInmediate"
        >
          <b-form-input
            v-model="member.contractual_condition.transfer.address.zip_code"
            type="text"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('postal-code')"
            @change="() => saveDataTemporarily()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- Country -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label>{{ $t('generic.country') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          name="transferCountry"
          :immediate="isInmediate"
        >
          <v-select
            id="address-id"
            v-model="member.contractual_condition.transfer.address.country_hash"
            label="name"
            :options="countries"
            :reduce="country => country.id"
            :placeholder="$t('generic.selectCountry')"
            @input="() => saveDataTemporarily()"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- State -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label>{{ $t('generic.state') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          name="transferState"
          :immediate="isInmediate"
        >
          <v-select
            id="state-id"
            v-model="member.contractual_condition.transfer.address.state_hash"
            label="name"
            :options="addressTransferStates"
            :reduce="item => item.hash"
            :disabled="member.contractual_condition.transfer.address.country_hash == null"
            :placeholder="$t('generic.selectState')"
            @input="() => saveDataTemporarily()"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- Email -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label>{{ $t('generic.email') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required|email"
          name="transferEmail"
          :immediate="isInmediate"
        >
          <b-form-input
            v-model="member.contractual_condition.transfer.email"
            type="text"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('generic.email')"
            @change="() => saveDataTemporarily()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- type_jurisdiction_hash -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label>{{ $t('jurisdiction-and-jurisdiction') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          name="transferJurisdiction"
          :immediate="isInmediate"
        >
          <v-select
            id="club-id"
            v-model="member.contractual_condition.transfer.type_jurisdiction"
            label="name"
            :options="typeJurisdictions"
            :placeholder="$t('select-jurisdiction')"
            @input="() => saveDataTemporarily()"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- Other jurisdiction -->
    <template v-if="transferJurisdictionKey === 'OTHER' || transferJurisdictionKey === 'FEDERATION'">
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <b-form-group>
          <label>{{ $t('add-jurisdiction-and-venue') }} <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required|max:254|alphaNumSpacesRegex"
            name="transferJurisdictionOther"
            :immediate="isInmediate"
          >
            <b-form-input
              v-model="member.contractual_condition.transfer.jurisdiction_other"
              type="text"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('describe-jurisdiction-and-venue')"
              @change="() => saveDataTemporarily()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </template>

    <!-- Legislacion aplicable al contrato -->
    <b-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
    >

      <b-form-group>
        <label>{{ $t('law-applicable-to-the-contract') }} <span class="text-danger">*</span></label>
        <rich-text-edit
          :model-value.sync="member.contractual_condition.transfer.law_applicable_contract"
          :placeholder="$t('escribe-law-applicable-to-the-contract')"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import { isObject, hasKeyObject } from '@core/utils/utils'
import { mapActions, mapGetters } from 'vuex'
import RichTextEdit from '@/views/member/components/RichTextEdit.vue'

export default {
  components: {
    ValidationProvider,
    vSelect,
    RichTextEdit,
  },
  props: {
    member: {
      type: Object,
      default: null,
    },
    countries: {
      type: Array,
      default: null,
    },
    typeJurisdictions: {
      type: Array,
      default: null,
    },
    option: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      email,
      transferJurisdictionKey: null,
      addressTransferStates: [],
    }
  },
  computed: {
    ...mapGetters({
      genderHash: 'memberStore/selectGenderHash',
    }),
    isInmediate() {
      return this.member.is_complete_data === true
    },
  },
  watch: {
    'member.contractual_condition.transfer.type_jurisdiction': function transferJurisdictionOption(jurisdiction) {
      if (isObject(jurisdiction)) {
        this.member.contractual_condition.transfer.type_jurisdiction_hash = jurisdiction.id
        this.transferJurisdictionKey = jurisdiction.key
      } else {
        this.member.contractual_condition.transfer.jurisdiction_other = null
        this.transferJurisdictionKey = null
      }
    },
    'member.contractual_condition.transfer.address.country_hash': function conditionsTransferNotificationAddressCountryId(pCountry, oldCountry) {
      if (pCountry != null) {
        this.getAddressTransferStates(pCountry)
      }
      if (oldCountry) {
        this.member.contractual_condition.transfer.address.state_hash = null
      }
    },
    'member.contractual_condition.transfer.law_applicable_contract': function memberConditionsTransferLawApplicableContract() {
      this.saveDataTemporarily()
    },
  },
  mounted() {
    if (isObject(this.member.contractual_condition.transfer.address) && hasKeyObject(this.member.contractual_condition.transfer.address, 'country_hash')) {
      this.$nextTick(async () => {
        await this.getAddressTransferStates(this.member.contractual_condition.transfer.address.country_hash)
      })
    }
    if (isObject(this.member.contractual_condition.transfer.type_jurisdiction) && hasKeyObject(this.member.contractual_condition.transfer.type_jurisdiction, 'key')) {
      this.transferJurisdictionKey = this.member.contractual_condition.transfer.type_jurisdiction.key
    }
  },
  methods: {
    ...mapActions({
      fetchStateByCountryId: 'fetchStateByCountryId',
    }),
    saveDataTemporarily() {
      localStorage.setItem(`memberData${this.genderHash}`, JSON.stringify(this.member))
    },
    async getAddressTransferStates(pCountry) {
      await this.fetchStateByCountryId(pCountry).then(response => {
        this.addressTransferStates = response.data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

</style>
