<template>
  <div>
    <b-row>
      <b-col
        sm="10"
        md="4"
        lg="4"
        class="mb-2"
      >
        <h5 class="mb-0">
          <div v-if="option == sectionPlayer">
            {{ $t('register-of') }}  {{ gender.toLowerCase() }}
          </div>
           <!-- $t('member.form.registerPlayerMale') -->
          <!-- <div v-if="option == sectionPlayerFemale">
            {{ $t('member.form.registerPlayerFemale') }}
          </div> -->
          <div v-if="option == sectionCoachingStaff">
            {{ $t('member.form.registerCoachingStaff') }}
          </div>
          <!-- {{ $t('member.form.title') }} -->
        </h5>
        <small class="text-muted">
          {{ $t('member.form.subTitle') }}
        </small>
      </b-col>
    </b-row>
  </div>
</template>
<script>

export default {
  props: {
    sectionPlayer: {
      type: String,
      default: null,
    },
    sectionCoachingStaff: {
      type: String,
      default: null,
    },
    option: {
      type: String,
      default: null,
    },
    gender: {
      type: String,
      default: null,
    },
  },
}
</script>
<style lang="">

</style>
