<template>
  <b-row>
    <!-- Condiciones de incremento -->
    <b-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
    >
      <b-form-group>
        <label>{{ $t('increase-conditions') }} <span class="text-danger">*</span></label>
        <!-- <validation-provider
          #default="{ errors }"
          rules="required"
          name="generalIncreaseConditions"
        > -->
        <rich-text-edit
          :model-value.sync="member.contractual_condition.increase_conditions"
          :placeholder="$t('describe-increase-conditions')"
        />
        <!-- <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider> -->
      </b-form-group>
    </b-col>
    <!--Forma pago -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label>{{ $t('method-payment') }}<span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required|max:254|alphaNumSpacesRegex"
          name="generalMethodPayment"
          :immediate="isInmediate"
        >
          <b-form-input
            v-model="member.contractual_condition.method_payment"
            type="text"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('describe-payment-method')"
            @change="() => saveDataTemporarily()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- periodicidad -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label>{{ $t('periodicity') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          name="generalPeriodicity"
          :immediate="isInmediate"
        >
          <v-select
            id="periodicidad-id"
            v-model="member.contractual_condition.payment_period"
            label="name"
            :options="typePaymentperiods"
            :placeholder="$t('select-periodicity')"
            @input="() => saveDataTemporarily()"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <template v-if="paymentPeriodkey === 'OTHER'">
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <b-form-group>
          <label>{{ $t('other-periodicity') }}<span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required|max:254|alphaNumSpacesRegex"
            name="generalPeriodicityOther"
            :immediate="isInmediate"
          >
            <b-form-input
              v-model="member.contractual_condition.payment_period_other"
              type="text"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('describe-periodicity')"
              @change="() => saveDataTemporarily()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </template>

    <!-- Bonos -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label>{{ $t('bonds') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required|max:254|alphaNumSpacesRegex"
          name="generalBonds"
          :immediate="isInmediate"
        >
          <b-form-input
            v-model="member.contractual_condition.bonds"
            type="text"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('describe-bonds')"
            @change="() => saveDataTemporarily()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- beneficios -->
    <b-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
    >
      <benefits-form
        :member.sync="member"
        :color-primary-btn="colorPrimaryBtn"
      />
    </b-col>

    <!-- Indemnizacion por terminacion anticipada -->
    <b-col
      cols="12"
      sm="12"
      md="12"
      lg="6"
      xl="6"
    >
      <b-form-group>
        <label>{{ $t('compensation-for-early-termination') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required|max:254|alphaNumSpacesRegex"
          name="generalCompensationForEarlyTermination"
          :immediate="isInmediate"
        >
          <b-form-input
            v-model="member.contractual_condition.compensation_amount"
            type="text"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('amount-of-compensation')"
            @change="() => saveDataTemporarily()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <div class="w-100" />

    <!-- Detalles de indemnización -->
    <b-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
    >
      <b-form-group>
        <label>{{ $t('compensation-details') }} <span class="text-danger">*</span></label>
        <!-- <validation-provider
          #default="{ errors }"
          rules="required"
          name="generalCompensationDetails"
        > -->

        <rich-text-edit
          :model-value.sync="member.contractual_condition.compensation_details"
          :placeholder="$t('add-compensation-details')"
        />
        <!-- <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider> -->
      </b-form-group>
    </b-col>

    <div class="w-100" />

    <!-- Penalización -->
    <b-col
      cols="12"
      sm="12"
      md="12"
      lg="6"
      xl="6"
    >
      <b-form-group>
        <label>{{ $t('penalty') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required|max:254|alphaNumSpacesRegex"
          name="generalPenaltyAmount"
          :immediate="isInmediate"
        >
          <b-form-input
            v-model="member.contractual_condition.penalty_amount"
            type="text"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('amount-of-penalty')"
            @change="() => saveDataTemporarily()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <div class="w-100" />

    <!-- Detalles de penalización -->
    <b-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
    >
      <b-form-group>
        <label>{{ $t('penalty-details') }} <span class="text-danger">*</span></label>
        <!-- <validation-provider
          #default="{ errors }"
          rules="required"
          name="generalPenaltyDetails"
        > -->
        <rich-text-edit
          :model-value.sync="member.contractual_condition.penalty_details"
          :placeholder="$t('add-penalty-details')"
        />
        <!-- <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider> -->
      </b-form-group>
    </b-col>
    <div class="w-100" />

    <!-- Rescisión -->
    <b-col
      cols="12"
      sm="12"
      md="12"
      lg="6"
      xl="6"
    >
      <b-form-group>
        <label>{{ $t('rescission') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required|max:254|alphaNumSpacesRegex"
          name="generalRescission"
          :immediate="isInmediate"
        >
          <b-form-input
            v-model="member.contractual_condition.rescission_amount"
            type="text"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('rescission-amount')"
            @change="() => saveDataTemporarily()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <div class="w-100" />

    <!-- Detalles de rescisión -->
    <b-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
    >
      <b-form-group>
        <label>{{ $t('rescission-details') }} <span class="text-danger">*</span></label>
        <rich-text-edit
          :model-value.sync="member.contractual_condition.rescission_details"
          :placeholder="$t('add-rescission-details')"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import RichTextEdit from '@/views/member/components/RichTextEdit.vue'
import { isObject } from '@core/utils/utils'
import { required, email } from '@validations'
import { mapGetters } from 'vuex'
import BenefitsForm from '../components/BenefitsForm.vue'

export default {
  name: 'MethodPayment',
  components: {
    ValidationProvider,
    vSelect,
    RichTextEdit,
    BenefitsForm,
  },
  props: {
    member: {
      type: Object,
      default: null,
    },
    typePaymentperiods: {
      type: Array,
      default: null,
    },
    option: {
      type: String,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      email,
      paymentPeriodkey: null,
    }
  },
  computed: {
    ...mapGetters({
      genderHash: 'memberStore/selectGenderHash',
      typeMemberStorage: 'memberStore/typeMemberStorage',
    }),
    isInmediate() {
      return this.member.is_complete_data === true
    },
  },
  watch: {
    'member.contractual_condition.payment_period': function paymentPeriodOption(period) {
      if (isObject(period)) {
        this.member.contractual_condition.payment_period_hash = period.id
        this.paymentPeriodkey = period.key
      } else {
        this.member.contractual_condition.payment_period_other = null
        this.paymentPeriodkey = null
      }
    },
    'member.contractual_condition.increase_conditions': function memberConditionsIncreaseConditions() {
      this.saveDataTemporarily()
    },
    'member.contractual_condition.compensation_details': function memberConditionsCompensationDetails() {
      this.saveDataTemporarily()
    },
    'member.contractual_condition.penalty_details': function memberConditionsPenaltyDetails() {
      this.saveDataTemporarily()
    },
    'member.contractual_condition.rescission_details': function memberConditionsRescissionDetails() {
      this.saveDataTemporarily()
    },
  },
  methods: {
    saveDataTemporarily() {
      localStorage.setItem(this.typeMemberStorage, JSON.stringify(this.member))
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

</style>
