<template>
  <div>
    <b-card
      card-body
      class="floating-profile"
      :class="{'show': y > 250}"
    >
      <div class="d-inline-flex d-md-inline-flex d-sm-inline-flex user-nav mr-1">
        <p class="user-name font-weight-bolder mb-0 text-dark">
          {{ name }}
        </p>
      </div>
      <b-avatar
        size="40"
        :src="avatar"
        variant="light-dark"
        class="badge-minimal d-md-inline-flex d-sm-inline-flex i-shadow-new"
      >
        <i
          v-if="!avatar"
          class="icon-0-icons-dark-avatar i-icon-size-avatar"
        />
      </b-avatar>
    </b-card>
  </div>
</template>
<script>
import { useWindowScroll } from '@vueuse/core'

export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    avatar: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { y } = useWindowScroll()

    const scrollToTop = () => {
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    return { y, scrollToTop }
  },
}
</script>
<style lang="scss">
// floating nav
.floating-profile {
  position: fixed;
  right: 27px;
  top: 20px;
  z-index: 12;
  opacity: 0;
  transition: all .5s ease;
  background-color: #fafafa;
  text-align: right;
  &.show {
      opacity: 1;
      // transform: translateY(0)
      box-shadow: 2px 3px 10px 0 rgba(81, 81, 81, 0.1) !important;
    }
}
@media (max-width: 415px) {
  .floating-profile {
   top: 0px;
   width: auto;
   left: 27px;
   right: 27px;
   padding: 0%;
   .card-body {
    padding: .5rem;
   }
  }
}
</style>
