var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b',[_vm._v(_vm._s(_vm.$t('question.playerAgent')))])]),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-radio-group',{staticClass:"mb-2",attrs:{"options":_vm.controlOptions},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.player_agent_key),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "player_agent_key", $$v)},expression:"member.contractual_condition.player_agent_key"}}),_c('hr')],1),(_vm.member.contractual_condition.player_agent_key === 'YES')?[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.name'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|nameRegex","name":"generalAgentName","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('full-name')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.player_agent.name),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.player_agent, "name", $$v)},expression:"member.contractual_condition.player_agent.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4249672675)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('licence-number'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalAgentLicence","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('enter-license')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.player_agent.license_number),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.player_agent, "license_number", $$v)},expression:"member.contractual_condition.player_agent.license_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4231501892)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('activity-or-role-in-the-contract'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalAgentRole","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('generic.enterRole')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.player_agent.role),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.player_agent, "role", $$v)},expression:"member.contractual_condition.player_agent.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4021695434)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('payment'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"integer|max:16","name":"generalAgentPayment","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('payment-amount')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.player_agent.payment_amount),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.player_agent, "payment_amount", $$v)},expression:"member.contractual_condition.player_agent.payment_amount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3101644508)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('method-payment'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalAgentMethodPayment","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('describe-payment-method')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.player_agent.method_payment),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.player_agent, "method_payment", $$v)},expression:"member.contractual_condition.player_agent.method_payment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1720109743)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('periodicity'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"generalAgentPeriodicity","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"periodicityid","label":"name","options":_vm.typePaymentperiods,"placeholder":_vm.$t('select-periodicity')},on:{"input":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.player_agent.payment_period),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.player_agent, "payment_period", $$v)},expression:"member.contractual_condition.player_agent.payment_period"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3109906418)})],1)],1),(_vm.generalPaymentPeriodkey === 'OTHER')?[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('other-periodicity'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalAgentPeriodicityOther","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('describe-periodicity')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.player_agent.payment_period_other),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.player_agent, "payment_period_other", $$v)},expression:"member.contractual_condition.player_agent.payment_period_other"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3448977837)})],1)],1)]:_vm._e(),_c('div',{staticClass:"w-100"}),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('b',[_vm._v(_vm._s(_vm.$t('generic.validity')))]),_c('hr')]),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.initDate'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"generalAgentInitDate","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"locale":_vm.$i18n.locale,"placeholder":_vm.$t('select-date'),"state":errors.length > 0 ? false : null,"date-info-fn":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.player_agent.init_effective_date),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.player_agent, "init_effective_date", $$v)},expression:"member.contractual_condition.player_agent.init_effective_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2648875583)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.endDate'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"generalAgentEndDate","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"locale":_vm.$i18n.locale,"min":_vm.member.contractual_condition.player_agent.init_effective_date,"placeholder":_vm.$t('select-date'),"state":errors.length > 0 ? false : null,"date-info-fn":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.player_agent.end_effective_date),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.player_agent, "end_effective_date", $$v)},expression:"member.contractual_condition.player_agent.end_effective_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1266067864)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('upload-file',{attrs:{"title":_vm.$t('generic.uploadDocument'),"folder":"members","disabled":_vm.member.club_hash == null,"club-id":_vm.member.club_hash,"file-title":_vm.member.contractual_condition.player_agent.file_document ? _vm.member.contractual_condition.player_agent.file_document.title : ''},on:{"uploaded-file":_vm.assignFileDocument}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }