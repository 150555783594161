<template>
  <b-row>
    <!-- Question -->
    <b-col
      cols="12"
      class="mt-1"
    >
      <b>{{ $t('question.playerAgent') }}</b>
    </b-col>
    <!-- Intervino Agente -->
    <b-col
      cols="12"
      class="mt-1"
    >
      <b-form-radio-group
        v-model="member.contractual_condition.player_agent_key"
        :options="controlOptions"
        class="mb-2"
        @change="() => saveDataTemporarily()"
      />
      <hr>
    </b-col>

    <!-- Agent -->
    <template v-if="member.contractual_condition.player_agent_key === 'YES'">
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <b-form-group>
          <label>{{ $t('generic.name') }} <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required|max:254|nameRegex"
            name="generalAgentName"
            :immediate="isInmediate"
          >
            <b-form-input
              v-model="member.contractual_condition.player_agent.name"
              type="text"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('full-name')"
              @change="() => saveDataTemporarily()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Numero de licencia -->
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <b-form-group>
          <label>{{ $t('licence-number') }} <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required|max:254|alphaNumSpacesRegex"
            name="generalAgentLicence"
            :immediate="isInmediate"
          >
            <b-form-input
              v-model="member.contractual_condition.player_agent.license_number"
              type="text"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('enter-license')"
              @change="() => saveDataTemporarily()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Rol -->
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <b-form-group>
          <label>{{ $t('activity-or-role-in-the-contract') }} <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required|max:254|alphaNumSpacesRegex"
            name="generalAgentRole"
            :immediate="isInmediate"
          >
            <b-form-input
              v-model="member.contractual_condition.player_agent.role"
              type="text"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('generic.enterRole')"
              @change="() => saveDataTemporarily()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- pago -->
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <b-form-group>
          <label>{{ $t('payment') }}<span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="integer|max:16"
            name="generalAgentPayment"
            :immediate="isInmediate"
          >
            <b-input-group
              prepend="$"
              class="input-group-merge"
            >
              <b-form-input
                v-model="member.contractual_condition.player_agent.payment_amount"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('payment-amount')"
                @change="() => saveDataTemporarily()"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!--Forma pago -->
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <b-form-group>
          <label>{{ $t('method-payment') }}<span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required|max:254|alphaNumSpacesRegex"
            name="generalAgentMethodPayment"
            :immediate="isInmediate"
          >
            <b-form-input
              v-model="member.contractual_condition.player_agent.method_payment"
              type="text"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('describe-payment-method')"
              @change="() => saveDataTemporarily()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- periodicidad -->
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <b-form-group>
          <label>{{ $t('periodicity') }}<span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="generalAgentPeriodicity"
            :immediate="isInmediate"
          >
            <v-select
              id="periodicityid"
              v-model="member.contractual_condition.player_agent.payment_period"
              label="name"
              :options="typePaymentperiods"
              :placeholder="$t('select-periodicity')"
              @input="() => saveDataTemporarily()"
            >
              <div slot="no-options">
                {{ $t('generic.noRecordsFound') }}
              </div>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <template v-if="generalPaymentPeriodkey === 'OTHER'">
        <b-col
          cols="12"
          sm="12"
          md="6"
          lg="3"
          xl="3"
        >
          <b-form-group>
            <label>{{ $t('other-periodicity') }}<span class="text-danger">*</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required|max:254|alphaNumSpacesRegex"
              name="generalAgentPeriodicityOther"
              :immediate="isInmediate"
            >
              <b-form-input
                v-model="member.contractual_condition.player_agent.payment_period_other"
                type="text"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('describe-periodicity')"
                @change="() => saveDataTemporarily()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </template>
      <div class="w-100" />

      <!-- Vigencia -->
      <b-col
        cols="6"
        sm="12"
        md="12"
        lg="6"
        xl="6"
        class="mt-1"
      >
        <b>{{ $t('generic.validity') }}</b>
        <hr>
      </b-col>
      <div class="w-100" />
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <b-form-group>
          <label>{{ $t('generic.initDate') }} <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="generalAgentInitDate"
            :immediate="isInmediate"
          >
            <b-form-datepicker
              v-model="member.contractual_condition.player_agent.init_effective_date"
              :locale="$i18n.locale"
              class="form-control"
              :placeholder="$t('select-date')"
              :state="errors.length > 0 ? false : null"
              :date-info-fn="() => saveDataTemporarily()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <!-- Fecha final -->
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <b-form-group>
          <label>{{ $t('generic.endDate') }} <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="generalAgentEndDate"
            :immediate="isInmediate"
          >
            <b-form-datepicker
              v-model="member.contractual_condition.player_agent.end_effective_date"
              :locale="$i18n.locale"
              :min="member.contractual_condition.player_agent.init_effective_date"
              class="form-control"
              :placeholder="$t('select-date')"
              :state="errors.length > 0 ? false : null"
              :date-info-fn="() => saveDataTemporarily()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Document -->
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <upload-file
          :title="$t('generic.uploadDocument')"
          folder="members"
          :disabled="member.club_hash == null"
          :club-id="member.club_hash"
          :file-title="member.contractual_condition.player_agent.file_document ? member.contractual_condition.player_agent.file_document.title : ''"
          @uploaded-file="assignFileDocument"
        />
        <!-- <b-form-group>
          <label>{{ $t('generic.uploadDocument') }} <span class="text-danger">*</span></label>
          <b-form-file
            v-model="member.contractual_condition.player_agent.document_path"
            :browse-text="$t('buttons.explore')"
            accept="image/jpeg, image/png, application/pdf"
            :placeholder="$t('member.form.placeholder.uploadDocument')"
            :drop-placeholder="$t('member.form.placeholder.uploadDocument')"
            no-drop
          />
        </b-form-group> -->
      </b-col>
    </template>
  </b-row>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import UploadFile from '@/views/member/components/UploadFile.vue'
import { mapActions, mapGetters } from 'vuex'
import { required, email } from '@validations'
import { isObject, hasKeyObject } from '@core/utils/utils'

export default {
  components: {
    ValidationProvider,
    vSelect,
    UploadFile,
  },
  props: {
    member: {
      type: Object,
      default: null,
    },
    controlOptions: {
      type: Array,
      default: null,
    },
    typePaymentperiods: {
      type: Array,
      default: null,
    },
    option: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      email,
      generalPaymentPeriodkey: null,
    }
  },
  computed: {
    ...mapGetters({
      genderHash: 'memberStore/selectGenderHash',
      typeMemberStorage: 'memberStore/typeMemberStorage',
    }),
    isInmediate() {
      return this.member.is_complete_data === true
    },
  },
  watch: {
    'member.contractual_condition.player_agent.payment_period': function generalPaymentPeriodOption(period) {
      if (isObject(period)) {
        this.member.contractual_condition.player_agent.payment_period_hash = period.id
        this.generalPaymentPeriodkey = period.key
      } else {
        this.member.contractual_condition.player_agent.payment_period_other = null
        this.generalPaymentPeriodkey = null
      }
    },
  },
  mounted() {
    // Only edit
    if (isObject(this.member.contractual_condition.player_agent.payment_period) && hasKeyObject(this.member.contractual_condition.player_agent.payment_period, 'key')) {
      this.generalPaymentPeriodkey = this.member.contractual_condition.player_agent.payment_period.key
    }
  },
  methods: {
    ...mapActions({
      mediaFileDestroy: 'mediaFile/destroy',
    }),
    saveDataTemporarily() {
      localStorage.setItem(this.typeMemberStorage, JSON.stringify(this.member))
    },
    assignFileDocument(responseFile) {
      const previousFileHash = this.member.contractual_condition.player_agent.path_file_document_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member.contractual_condition.player_agent, 'file_document', responseFile)
      this.$set(this.member.contractual_condition.player_agent, 'path_file_document_media_file_hash', responseFile.hash)
      // Object.assign(this.member.contractual_condition.player_agent.file_document, responseFile)
      // this.member.contractual_condition.player_agent.path_file_document_media_file_hash = responseFile.hash
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

</style>
