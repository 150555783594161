var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),(_vm.option === _vm.sectionCoachingStaff)?[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(" "+_vm._s(_vm.$t('title-or-licence'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalTypeContractTitleOrLicence","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('generic.code')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.title_or_licence),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "title_or_licence", $$v)},expression:"member.contractual_condition.title_or_licence"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2287469826)})],1)],1)]:_vm._e(),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(" "+_vm._s(_vm.$t('type-contract'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"generalTypeContract","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.typeContracts,"placeholder":_vm.$t('select-type-of-contract')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.type_contract),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "type_contract", $$v)},expression:"member.contractual_condition.type_contract"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.typeContractKey === 'OTHER')?[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(" "+_vm._s(_vm.$t('add-another-contract-type'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalTypeContractOther","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('describe-type-of-contract')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.type_contract_other),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "type_contract_other", $$v)},expression:"member.contractual_condition.type_contract_other"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3496266219)})],1)],1)]:_vm._e(),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(" "+_vm._s(_vm.$t('representative-or-agent'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"generalRepresentative","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"representative-hash","label":"name","options":_vm.representativesList,"reduce":function (item) { return item.key; },"placeholder":_vm.$t('select-legal-representative')},on:{"input":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.assign_representative_key),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "assign_representative_key", $$v)},expression:"member.contractual_condition.assign_representative_key"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('signature-date'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"generalContractSignatureDate","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"locale":_vm.$i18n.locale,"placeholder":_vm.$t('member.form.placeholder.selectDate'),"state":errors.length > 0 ? false : null,"date-info-fn":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.signature_date),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "signature_date", $$v)},expression:"member.contractual_condition.signature_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"}),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b',[_vm._v(_vm._s(_vm.$t('generic.validity')))]),_c('hr')]),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.initDate'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"generalContractFreeInitDate","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"locale":_vm.$i18n.locale,"placeholder":_vm.$t('select-date'),"state":errors.length > 0 ? false : null,"date-info-fn":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.init_date),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "init_date", $$v)},expression:"member.contractual_condition.init_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.endDate'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"generalContractFreeEndDate","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"locale":_vm.$i18n.locale,"min":_vm.member.contractual_condition.init_date,"placeholder":_vm.$t('select-date'),"state":errors.length > 0 ? false:null,"date-info-fn":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.end_date),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "end_date", $$v)},expression:"member.contractual_condition.end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b',[_vm._v(_vm._s(_vm.$t('consideration')))]),_c('hr')]),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"general-fixed-quantity","value":true},model:{value:(_vm.member.contractual_condition.against_provision.has_fixed_quantity),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.against_provision, "has_fixed_quantity", $$v)},expression:"member.contractual_condition.against_provision.has_fixed_quantity"}},[_c('label',{attrs:{"for":"general-fixed-quantity"}},[_vm._v(_vm._s(_vm.$t('fixed-quantity')))])]),(_vm.member.contractual_condition.against_provision.has_fixed_quantity)?_c('validation-provider',{attrs:{"rules":"integer|max:16","name":"generalFixedQuantity","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('placeholder-quantity')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.against_provision.fixed_quantity),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.against_provision, "fixed_quantity", $$v)},expression:"member.contractual_condition.against_provision.fixed_quantity"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3069946797)}):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"general-variable-quantity","value":true},model:{value:(_vm.member.contractual_condition.against_provision.has_variable_quantity),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.against_provision, "has_variable_quantity", $$v)},expression:"member.contractual_condition.against_provision.has_variable_quantity"}},[_c('label',{attrs:{"for":"general-variable-quantity"}},[_vm._v(_vm._s(_vm.$t('variable-quantity')))])]),(_vm.member.contractual_condition.against_provision.has_variable_quantity)?_c('validation-provider',{attrs:{"rules":"integer|max:16","name":"generalVariableQuantity","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('placeholder-quantity')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.against_provision.variable_quantity),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.against_provision, "variable_quantity", $$v)},expression:"member.contractual_condition.against_provision.variable_quantity"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1001921853)}):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }