<template>
  <div>
    <b-row
      v-for="(item, index) in member.contractual_condition.increments"
      :key="item.id"
    >
      <b-col
        cols="9"
        sm="9"
        md="10"
        lg="5"
        xl="5"
      >
        <b-form-group>
          <label>{{ $t('increase') }} <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required|max:254|alphaNumSpacesRegex"
            name="generalIncrease"
          >
            <b-form-input
              v-model="member.contractual_condition.increments[index].value"
              type="text"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('quantity-or-percentage')"
              @change="() => saveDataTemporarily()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="auto"
        xl="auto"
        lg="auto"
        md="auto"
        sm="auto"
        class="m-0 p-0"
      >
        <div class="float-right ml-0 mr-0">

          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="secondary"
            style="margin-top: 22px;"
            pill
            @click="removeIncrement(index)"
          >
            {{ $t('buttons.putOff') }}
          </b-button>

        </div>
      </b-col>
    </b-row>
    <div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-1"
        :style="colorPrimaryBtn"
        pill
        @click.prevent="addIncrement"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('buttons.add-increase') }}</span>
      </b-button>
    </div>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import { ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  components: {
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    member: {
      type: Object,
      default: null,
    },
    option: {
      type: String,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      email,
      valueIncrement: null,
    }
  },
  computed: {
    ...mapGetters({
      genderHash: 'memberStore/selectGenderHash',
      typeMemberStorage: 'memberStore/typeMemberStorage',
    }),
  },
  methods: {
    addIncrement() {
      this.member.contractual_condition.increments.push({
        id: Date.now(),
        value: this.valueIncrement,
      })
    },
    removeIncrement(index) {
      this.member.contractual_condition.increments.splice(index, 1)
    },
    saveDataTemporarily() {
      localStorage.setItem(this.typeMemberStorage, JSON.stringify(this.member))
    },
  },
}
</script>
