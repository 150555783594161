<template>
  <div>
    <overlay-content :loading="isLoadingFile">
      <validation-observer
        ref="formFileUpload"
      >
        <b-form>
          <b-form-group>
            <label>{{ title }} </label>
            <span class="text-danger">*</span>
            <validation-provider
              #default="{ errors }"
              name="document"
              :rules="fileTitle == null ? 'required' : ''"
            >
              <b-form-file
                ref="documentFile"
                v-model="file_document"
                type="file"
                :browse-text="$t('buttons.explore')"
                :accept="acceptTypeFile"
                :placeholder="$t('generic.uploadDocument')"
                :state="errors.length > 0 ? false : null"
                no-drop
                :disabled="disabled"
                @input="fileValidation(file_document)"
              />
              <small
                class="text-muted"
              > {{ $t('generic.document') }}: <b class="text-primary">{{ fileTitle }}</b> </small> <br>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <!-- </template> -->
          </b-form-group>
        </b-form>
      </validation-observer>
    </overlay-content>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import { mapActions } from 'vuex'
import OverlayContent from '@/views/partials/OverlayContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    OverlayContent,
  },
  props: {
    folder: {
      type: String,
      default: '',
    },
    clubId: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Document',
    },
    fileTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      isLoadingFile: false,
      file_document: [],
      acceptTypeFile: 'image/jpeg, image/png, image/jpg, application/pdf',
    }
  },
  methods: {
    validatorFileSize,
    validatorFileType,
    ...mapActions({
      mediaFileUpload: 'mediaFile/upload',
    }),
    async fileValidation(fileData) {
      // const validSize = this.validatorFileSize(fileData)
      const validType = this.validatorFileType(fileData)

      // if (!validSize) {
      //   this.$refs.documentFile.reset()
      //
      //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
      //   return
      // }
      if (!validType) {
        this.$refs.documentFile.reset()

        this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
        return
      }
      // this.$emit('handle-file', )
      this.saveFileUpload(fileData)
    },
    async saveFileUpload(fileData) {
      this.isLoadingFile = true
      const formData = new FormData()
      formData.append('file', fileData)
      formData.append('folder', this.folder)
      if (this.clubId) {
        formData.append('club_id', this.clubId)
      }
      await this.mediaFileUpload(formData).then(response => {
        const { data } = response.data
        this.$emit('uploaded-file', data)
        this.isLoadingFile = false
        // this.showSuccess(this.$t('generic.document'), this.$t('the-image-has-been-stored-correctly'))
      }).catch(error => {
        this.isLoadingFile = false
        this.responseCatch(error)
      })
    },
  },
}
</script>
<style lang="">

</style>
