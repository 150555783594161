<template>
  <b-overlay
    :show="loading"
    :opacity="opacity"
  >
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          :variant="variant"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
    <!-- Content -->
    <slot />
  </b-overlay>
</template>

<script>
import {
  BOverlay,
} from 'bootstrap-vue'

export default {
  name: 'OverlayContent',
  components: {
    BOverlay,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    opacity: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // codeSpinner,
    }
  },
}
</script>
