var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b',[_vm._v(_vm._s(_vm.$t('notification-address')))]),_c('hr')]),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('address'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|addressRegex","name":"generalAddress","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('stree-and-number')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.address.address),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.address, "address", $$v)},expression:"member.contractual_condition.address.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('postal-code'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|zipRegex","name":"generalPostalCode","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('postal-code')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.address.zip_code),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.address, "zip_code", $$v)},expression:"member.contractual_condition.address.zip_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.country'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"generalCountry","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"address-id","label":"name","options":_vm.countries,"reduce":function (country) { return country.id; },"placeholder":_vm.$t('generic.selectCountry')},on:{"input":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.address.country_hash),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.address, "country_hash", $$v)},expression:"member.contractual_condition.address.country_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.state'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"generalState","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"state-id","label":"name","options":_vm.addressGeneralStates,"reduce":function (item) { return item.hash; },"disabled":_vm.member.contractual_condition.address.country_hash == null,"placeholder":_vm.$t('generic.selectState')},on:{"input":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.address.state_hash),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition.address, "state_hash", $$v)},expression:"member.contractual_condition.address.state_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.email'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|email","name":"generalEmail","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('generic.email')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.email),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "email", $$v)},expression:"member.contractual_condition.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('jurisdiction-and-jurisdiction'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"generalJurisdiction","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"club-id","label":"name","options":_vm.typeJurisdictions,"placeholder":_vm.$t('select-jurisdiction')},on:{"input":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.type_jurisdiction),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "type_jurisdiction", $$v)},expression:"member.contractual_condition.type_jurisdiction"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.generalJurisdictionKey === 'OTHER' || _vm.generalJurisdictionKey === 'FEDERATION')?[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('add-jurisdiction-and-venue'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalJurisdictionOther","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('describe-jurisdiction-and-venue')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.jurisdiction_other),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "jurisdiction_other", $$v)},expression:"member.contractual_condition.jurisdiction_other"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3575810715)})],1)],1)]:_vm._e(),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('law-applicable-to-the-contract'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('rich-text-edit',{attrs:{"model-value":_vm.member.contractual_condition.law_applicable_contract,"placeholder":_vm.$t('escribe-law-applicable-to-the-contract')},on:{"update:modelValue":function($event){return _vm.$set(_vm.member.contractual_condition, "law_applicable_contract", $event)},"update:model-value":function($event){return _vm.$set(_vm.member.contractual_condition, "law_applicable_contract", $event)}}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }