<template>
  <div>
    <b-row
      v-for="(item, index) in member.contractual_condition.benefits"
      :key="item.id"
    >
      <b-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <b-form-group>
          <label>{{ $t('other-benefits') }} <span class="text-danger">*</span></label>
          <!-- <validation-provider
            #default="{ errors }"
            rules="required"
            name="generalBenefits"
          > -->

          <rich-text-edit
            :model-value.sync="member.contractual_condition.benefits[index].value"
            :placeholder="$t('write-benefits')"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider> -->
        </b-form-group>
        <div class="float-right ml-0 mr-0">

          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="secondary"
            style="margin-top: 10px;"
            pill
            @click="removeBenefits(index)"
          >
            {{ $t('buttons.putOff') }}
          </b-button>

        </div>
      </b-col>
      <!-- <b-col
        cols="auto"
        xl="auto"
        lg="auto"
        md="auto"
        sm="auto"
        class="m-0 p-0"
      > -->
        <!-- <div class="float-right ml-0 mr-0">

          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="secondary"
            style="margin-top: 22px;"
            pill
            @click="removeBenefits(index)"
          >
            {{ $t('buttons.putOff') }}
          </b-button>

        </div> -->
      <!-- </b-col> -->
    </b-row>
    <div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="float-left mb-1"
        pill
        :style="colorPrimaryBtn"
        @click.prevent="addBenefits"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('add-benefits') }}</span>
      </b-button>
    </div>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
// import { ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import RichTextEdit from '@/views/member/components/RichTextEdit.vue'

export default {
  components: {
    // ValidationProvider,
    RichTextEdit,
  },
  directives: {
    Ripple,
  },
  props: {
    member: {
      type: Object,
      default: null,
    },
    option: {
      type: String,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      valueBenefits: null,
    }
  },
  computed: {
    ...mapGetters({
      genderHash: 'memberStore/selectGenderHash',
      typeMemberStorage: 'memberStore/typeMemberStorage',
    }),
  },
  methods: {
    addBenefits() {
      this.member.contractual_condition.benefits.push({
        id: Date.now(),
        value: this.valueBenefits,
      })
    },
    removeBenefits(index) {
      this.member.contractual_condition.benefits.splice(index, 1)
    },
    saveDataTemporarily() {
      localStorage.setItem(this.typeMemberStorage, JSON.stringify(this.member))
    },
  },
}
</script>
