var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('increase-conditions'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('rich-text-edit',{attrs:{"model-value":_vm.member.contractual_condition.increase_conditions,"placeholder":_vm.$t('describe-increase-conditions')},on:{"update:modelValue":function($event){return _vm.$set(_vm.member.contractual_condition, "increase_conditions", $event)},"update:model-value":function($event){return _vm.$set(_vm.member.contractual_condition, "increase_conditions", $event)}}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('method-payment'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalMethodPayment","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('describe-payment-method')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.method_payment),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "method_payment", $$v)},expression:"member.contractual_condition.method_payment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('periodicity'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"generalPeriodicity","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"periodicidad-id","label":"name","options":_vm.typePaymentperiods,"placeholder":_vm.$t('select-periodicity')},on:{"input":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.payment_period),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "payment_period", $$v)},expression:"member.contractual_condition.payment_period"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.paymentPeriodkey === 'OTHER')?[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('other-periodicity'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalPeriodicityOther","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('describe-periodicity')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.payment_period_other),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "payment_period_other", $$v)},expression:"member.contractual_condition.payment_period_other"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1432212182)})],1)],1)]:_vm._e(),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3","xl":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('bonds'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalBonds","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('describe-bonds')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.bonds),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "bonds", $$v)},expression:"member.contractual_condition.bonds"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('benefits-form',{attrs:{"member":_vm.member,"color-primary-btn":_vm.colorPrimaryBtn},on:{"update:member":function($event){_vm.member=$event}}})],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('compensation-for-early-termination'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalCompensationForEarlyTermination","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('amount-of-compensation')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.compensation_amount),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "compensation_amount", $$v)},expression:"member.contractual_condition.compensation_amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('compensation-details'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('rich-text-edit',{attrs:{"model-value":_vm.member.contractual_condition.compensation_details,"placeholder":_vm.$t('add-compensation-details')},on:{"update:modelValue":function($event){return _vm.$set(_vm.member.contractual_condition, "compensation_details", $event)},"update:model-value":function($event){return _vm.$set(_vm.member.contractual_condition, "compensation_details", $event)}}})],1)],1),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('penalty'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalPenaltyAmount","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('amount-of-penalty')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.penalty_amount),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "penalty_amount", $$v)},expression:"member.contractual_condition.penalty_amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('penalty-details'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('rich-text-edit',{attrs:{"model-value":_vm.member.contractual_condition.penalty_details,"placeholder":_vm.$t('add-penalty-details')},on:{"update:modelValue":function($event){return _vm.$set(_vm.member.contractual_condition, "penalty_details", $event)},"update:model-value":function($event){return _vm.$set(_vm.member.contractual_condition, "penalty_details", $event)}}})],1)],1),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('rescission'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"generalRescission","immediate":_vm.isInmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('rescission-amount')},on:{"change":function () { return _vm.saveDataTemporarily(); }},model:{value:(_vm.member.contractual_condition.rescission_amount),callback:function ($$v) {_vm.$set(_vm.member.contractual_condition, "rescission_amount", $$v)},expression:"member.contractual_condition.rescission_amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('rescission-details'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('rich-text-edit',{attrs:{"model-value":_vm.member.contractual_condition.rescission_details,"placeholder":_vm.$t('add-rescission-details')},on:{"update:modelValue":function($event){return _vm.$set(_vm.member.contractual_condition, "rescission_details", $event)},"update:model-value":function($event){return _vm.$set(_vm.member.contractual_condition, "rescission_details", $event)}}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }