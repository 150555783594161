<template>
  <div
    ref="editor"
    style="max-height: 250px; overflow: auto;"
  />
</template>
<script>
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'

export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
    }
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    const _this = this

    this.editor = new Quill(this.$refs.editor, {
      modules: {
        toolbar: [
          [
            {
              header: [1, 2, 3, 4, false],
            },
          ],
          ['bold', 'italic', 'underline', 'link'],
        ],
      },
      // theme: 'bubble',
      theme: 'snow',
      formats: ['bold', 'underline', 'header', 'italic', 'link'],
      placeholder: this.placeholder,
    })
    this.editor.root.innerHTML = this.modelValue
    this.editor.on('text-change', () => _this.update())
  },

  methods: {
    update: function update() {
      this.$emit(
        'update:modelValue',
        this.editor.getText() ? this.editor.root.innerHTML : '',
      )
      // const isValid = this.$refs.editor.validate()
      // if (isValid) {
      //   this.$emit('errors', [0])
      // }
    },
  },
}
</script>
