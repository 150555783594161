<template>
  <b-row>
    <b-col
      cols="12"
    >
      <hr>
    </b-col>
    <!-- Title or licence -->
    <template v-if="option === sectionCoachingStaff">
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <b-form-group>
          <label> {{ $t('title-or-licence') }} <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required|max:254|alphaNumSpacesRegex"
            name="generalTypeContractTitleOrLicence"
            :immediate="isInmediate"
          >
            <b-form-input
              v-model="member.contractual_condition.title_or_licence"
              type="text"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('generic.code')"
              @change="() => saveDataTemporarily()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </template>
    <!-- Tipo de contrato -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label> {{ $t('type-contract') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          name="generalTypeContract"
          :immediate="isInmediate"
        >
          <v-select
            v-model="member.contractual_condition.type_contract"
            label="name"
            :options="typeContracts"
            :placeholder="$t('select-type-of-contract')"
            @change="() => saveDataTemporarily()"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- Other jurisdiction -->
    <template v-if="typeContractKey === 'OTHER'">
      <b-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <b-form-group>
          <label> {{ $t('add-another-contract-type') }} <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            rules="required|max:254|alphaNumSpacesRegex"
            name="generalTypeContractOther"
            :immediate="isInmediate"
          >
            <b-form-input
              v-model="member.contractual_condition.type_contract_other"
              type="text"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('describe-type-of-contract')"
              @change="() => saveDataTemporarily()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </template>
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label> {{ $t('representative-or-agent') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          name="generalRepresentative"
          :immediate="isInmediate"
        >
          <!-- key not repit -->
          <v-select
            id="representative-hash"
            v-model="member.contractual_condition.assign_representative_key"
            label="name"
            :options="representativesList"
            :reduce="item => item.key"
            :placeholder="$t('select-legal-representative')"
            @input="() => saveDataTemporarily()"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- Fecha de firma -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label>{{ $t('signature-date') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          name="generalContractSignatureDate"
          :immediate="isInmediate"
        >
          <b-form-datepicker
            v-model="member.contractual_condition.signature_date"
            :locale="$i18n.locale"
            class="form-control"
            :placeholder="$t('member.form.placeholder.selectDate')"
            :state="errors.length > 0 ? false : null"
            :date-info-fn="() => saveDataTemporarily()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- Viegsncia -->
    <div class="w-100" />

    <!-- Vigencia -->
    <b-col
      cols="6"
      class="mt-1"
    >
      <b>{{ $t('generic.validity') }}</b>
      <hr>
    </b-col>
    <div class="w-100" />
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label>{{ $t('generic.initDate') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          name="generalContractFreeInitDate"
          :immediate="isInmediate"
        >
          <b-form-datepicker
            v-model="member.contractual_condition.init_date"
            :locale="$i18n.locale"
            class="form-control"
            :placeholder="$t('select-date')"
            :state="errors.length > 0 ? false : null"
            :date-info-fn="() => saveDataTemporarily()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <!-- Fecha final -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <label>{{ $t('generic.endDate') }} <span class="text-danger">*</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          name="generalContractFreeEndDate"
          :immediate="isInmediate"
        >
          <b-form-datepicker
            v-model="member.contractual_condition.end_date"
            :locale="$i18n.locale"
            :min="member.contractual_condition.init_date"
            class="form-control"
            :placeholder="$t('select-date')"
            :state="errors.length > 0 ? false:null"
            :date-info-fn="() => saveDataTemporarily()"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- Fechas -->
    <!-- Fecha inicial -->

    <!-- Contraprestacion -->
    <b-col
      cols="12"
      class="mt-1"
    >
      <b>{{ $t('consideration') }}</b>
      <hr>
    </b-col>
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <b-form-checkbox
          id="general-fixed-quantity"
          v-model="member.contractual_condition.against_provision.has_fixed_quantity"
          :value="true"
        >
          <label for="general-fixed-quantity">{{ $t('fixed-quantity') }}</label>
        </b-form-checkbox>
        <validation-provider
          v-if="member.contractual_condition.against_provision.has_fixed_quantity"
          #default="{ errors }"
          rules="integer|max:16"
          name="generalFixedQuantity"
          :immediate="isInmediate"
        >
          <b-input-group
            prepend="$"
            class="input-group-merge"
          >
            <b-form-input
              v-model="member.contractual_condition.against_provision.fixed_quantity"
              :state="errors.length > 0 ? false:null"
              :placeholder="$t('placeholder-quantity')"
              @change="() => saveDataTemporarily()"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <!-- Cantidad variable -->
    <b-col
      cols="12"
      sm="12"
      md="6"
      lg="3"
      xl="3"
    >
      <b-form-group>
        <b-form-checkbox
          id="general-variable-quantity"
          v-model="member.contractual_condition.against_provision.has_variable_quantity"
          :value="true"
        >
          <label for="general-variable-quantity">{{ $t('variable-quantity') }}</label>
        </b-form-checkbox>
        <validation-provider
          v-if="member.contractual_condition.against_provision.has_variable_quantity"
          #default="{ errors }"
          rules="integer|max:16"
          name="generalVariableQuantity"
          :immediate="isInmediate"
        >
          <b-input-group
            prepend="$"
            class="input-group-merge"
          >
            <b-form-input
              v-model="member.contractual_condition.against_provision.variable_quantity"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('placeholder-quantity')"
              @change="() => saveDataTemporarily()"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- Describir forma de pago -->

    <!-- Domicilio de notificacion -->

  </b-row>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'
import { isObject } from '@core/utils/utils'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  components: {
    ValidationProvider,
    vSelect,
  },
  props: {
    member: {
      type: Object,
      default: null,
    },
    typeContracts: {
      type: Array,
      default: null,
    },
    option: {
      type: String,
      default: null,
    },
    sectionCoachingStaff: {
      type: String,
      default: null,
    },
    representativesList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      required,
      email,
      typeContractKey: null,
    }
  },
  computed: {
    ...mapGetters({
      genderHash: 'memberStore/selectGenderHash',
      typeMemberStorage: 'memberStore/typeMemberStorage',
    }),
    isInmediate() {
      return this.member.is_complete_data === true
    },
  },
  watch: {
    'member.contractual_condition.type_contract': function typeContractOption(contract) {
      if (isObject(contract)) {
        this.member.contractual_condition.type_contract_hash = contract.id
        this.typeContractKey = contract.key
      } else {
        this.member.contractual_condition.type_contract_other = null
        this.typeContractKey = null
      }
      this.saveDataTemporarily()
    },
  },
  methods: {
    saveDataTemporarily() {
      localStorage.setItem(this.typeMemberStorage, JSON.stringify(this.member))
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

</style>
